<template>
    <div></div>
</template>

<script>
export default {
    name: 'SignUp'
};
</script>

<style scoped></style>
