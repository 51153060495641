<template>
    <login-form></login-form>
</template>

<script>
import LoginForm from '@/components/auth/LoginForm';
export default {
    name: 'SignIn',
    components: { LoginForm }
};
</script>

<style scoped></style>
