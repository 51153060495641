<template>
    <div>
        <h1>yoyoyo</h1>
    </div>
</template>

<script>
export default {
    name: 'PmSkills'
};
</script>

<style scoped></style>
