<template>
    <div class="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
            <div>
                <h2
                    class="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-gray-200"
                >
                    Sign in to your account
                </h2>
                <p class="mt-2 text-center text-sm text-gray-600">
                    Or
                    <a
                        href="#"
                        class="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                        Sign up for free
                    </a>
                </p>
            </div>
            <form class="mt-8 space-y-6" @submit.prevent>
                <input type="hidden" name="remember" value="true" />
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="email-address" class="sr-only"
                            >Email address</label
                        >
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            autocomplete="email"
                            required
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Email address"
                            v-model.trim.lazy="loginFormData.email.val"
                        />
                    </div>
                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autocomplete="current-password"
                            required
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Password"
                            v-model.trim.lazy="loginFormData.passwd.val"
                        />
                    </div>
                </div>

                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <input
                            id="remember_me"
                            name="remember_me"
                            type="checkbox"
                            class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label
                            for="remember_me"
                            class="ml-2 block text-sm text-gray-900"
                        >
                            Remember me
                        </label>
                    </div>

                    <div class="text-sm">
                        <a
                            href="#"
                            class="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                            Forgot your password?
                        </a>
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        @click="submitForm"
                    >
                        <span
                            class="absolute left-0 inset-y-0 flex items-center pl-3"
                        >
                            <!-- Heroicon name: solid/lock-closed -->
                            <svg
                                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                        Sign in
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
    name: 'LoginForm',
    setup() {
        // Init store
        const store = useStore();
        // Init router
        const route = useRoute();
        const router = useRouter();

        const loginFormData = reactive({
            email: {
                val: null,
                isValid: true
            },
            passwd: {
                val: null,
                isValid: true
            }
        });

        const isLoading = ref(false);
        const formIsValid = ref(true);
        const mode = ref('signin');
        const error = ref(null);
        const currentLang = computed(
            () => store.getters['langModule/GET_CURRENT_LANGUAGE']
        );

        // FORM VALIDATION
        function validateForm() {
            // We set the formIsValid to true by default.
            formIsValid.value = true;
            // If the form is invalid, we set the formIsValid to false.
            if (
                loginFormData.email.val === null ||
                !loginFormData.email.val.includes('@') ||
                loginFormData.email.val === ''
            ) {
                loginFormData.email.isValid = false;
                formIsValid.value = false;
            }
            if (
                loginFormData.passwd.val === null ||
                loginFormData.passwd.val.length < 6 ||
                loginFormData.passwd.val === ''
            ) {
                loginFormData.passwd.isValid = false;
                formIsValid.value = false;
            }
        }
        // Submit login form
        async function submitForm() {
            validateForm();
            if (!formIsValid.value) {
                return;
            }
            isLoading.value = true;
            try {
                // SIGN IN -- SEND HTTP(S) REQ
                await store.dispatch('authModule/userLogin', {
                    email: loginFormData.email.val,
                    password: loginFormData.passwd.val,
                    mode: mode.value
                });
                // Redirect the user to the requested URL
                const redirectUrl =
                    '/' +
                    (route.query.redirect ||
                        `/${currentLang.value}/developer-skills`);
                await router.replace(redirectUrl);
            } catch (e) {
                console.log(e);
                error.value = e.message || 'FAILED';
            }
            this.isLoading = false;
        }

        return { loginFormData, submitForm, isLoading };
    }
};
</script>

<style scoped></style>
